import * as React from "react"
import { Helmet } from 'react-helmet';
import "../style.scss";
import { AboveFold } from '../components/above-fold';
import { Header } from '../components/header';
import { Section } from '../components/section';
import { graphql, StaticQuery } from 'gatsby';
import { aboveFoldImages } from '../images/above-fold-images';
import { RandomProtopote } from '../components/random-protopote';

// styles
const pageStyles = {
  color: "white",
  padding: 0,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const paragraphStyles = {
  marginBottom: 48,
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <title>Protopotes</title>
        <link rel="canonical" href="https://protopotes.stream/" />
        <meta name="description" content="La communauté des streameurs crafteurs francophones"/>
        <meta property="og:url" content="https://protopotes.stream/" />
        <meta property="og:description" content="La communauté des streameurs crafteurs francophones" />
        <meta property="og:image" content="https://protopotes.stream/favicon.png" />
        <meta property="og:title" content="Les Protopotes" />
        <meta property="og:type" content="website" />
      </Helmet>
      
      <title>Les protopotes</title>
      
      <Header/>
      <StaticQuery query={graphql`
          query {
              file(relativePath: { eq: "lab_neodyme.jpg" }) {
                  id
              }
          }
      `} render={(data) => (
        <>
          <AboveFold images={aboveFoldImages}>
            <h1>
              Les Protopotes
            </h1>
            <p className="above-fold__description">
              De talentueux streamers créatifs de la catégorie Créateurs et Artisanat. <br/>
              Nous faisons de la couture, du dev, du code, de l'électronique, de la robotique, du bricolage, et d'autres crafts... <br/>
              BREF on fait des choses avec nos mains, et parfois même qu'on réfléchit. <br/>
              {/*Retrouvez la liste des gens bons ici: https://protopotes.durss.ninja/ <br/>*/}
            </p>
          </AboveFold>
          
          <Section className="section-random-protopote">
            <RandomProtopote/>
          </Section>
          
          {/*<Section className="section-news">*/}
          {/*  <h2 className="text-center">Quoi de neuf ?</h2>*/}
          {/*  <p>Lorem ipsum dolor sit amet, consectetur*/}
          {/*    adipiscing elit. Quisque ullamcorper, quam eu*/}
          {/*    aliquet dictum, tellus libero sodales lacus,*/}
          {/*    vel malesuada est purus scelerisque nulla.*/}
          {/*    Pellentesque maximus ligula nec turpis egestas*/}
          {/*    finibus. Etiam nisl sem, rutrum sed lacus eu,*/}
          {/*    laoreet lacinia tortor. Integer nibh eros,*/}
          {/*    feugiat et dolor vel, sollicitudin convallis*/}
          {/*    urna. Integer lacinia scelerisque sem eu*/}
          {/*    tristique. Nam eu nisl eu tellus consequat*/}
          {/*    semper. Donec fringilla augue et ante gravida, at*/}
          {/*    varius elit auctor. Nam laoreet sapien elementum,*/}
          {/*    facilisis elit et, mollis augue. Praesent inc*/}
          {/*    vulputate dolor.*/}
          {/*  </p>*/}
          {/*</Section>*/}
          {/*<ul style={listStyles}>*/}
          {/*  <li style={docLinkStyle}>*/}
          {/*    <a*/}
          {/*      style={linkStyle}*/}
          {/*      href={`${docLink.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}*/}
          {/*    >*/}
          {/*      {docLink.text}*/}
          {/*    </a>*/}
          {/*  </li>*/}
          {/*  {links.map(link => (*/}
          {/*    <li key={link.url} style={{ ...listItemStyles, color: link.color }}>*/}
          {/*      <span>*/}
          {/*        <a*/}
          {/*          style={linkStyle}*/}
          {/*          href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}*/}
          {/*        >*/}
          {/*          {link.text}*/}
          {/*        </a>*/}
          {/*        {link.badge && (*/}
          {/*          <span style={badgeStyle} aria-label="New Badge">*/}
          {/*            NEW!*/}
          {/*          </span>*/}
          {/*        )}*/}
          {/*        <p style={descriptionStyle}>{link.description}</p>*/}
          {/*      </span>*/}
          {/*    </li>*/}
          {/*  ))}*/}
          {/*</ul>*/}
        </>
      )} />
    </main>
  )
}

export default IndexPage;
