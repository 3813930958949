import React from 'react'; //eslint-disable-line no-unused-vars
import Styled from 'styled-components';
import axios from 'axios';
import { LoadingView } from './loading-view';

function arrayShuffle(array) {
  let currentIndex = array.length,  randomIndex;
  
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  
  return array;
}

let userNames;

class RandomProtopote extends React.Component {
  static propTypes = {};
  
  state = {
    error: null,
    loadedUserNames: false,
    userDescription: false,
    selectedUser: 0,
  }
  
  constructor(props) {
    super(props);
    this.fetchUserNames().then(() => {
      return this.fetchUser();
    });
  }
  
  async fetchUserNames() {
    try {
      const userNamesRaw = await axios.get('https://raid.protopotes.stream/api/user_names');
      console.log(userNamesRaw);
      userNames = arrayShuffle(userNamesRaw.data.data);
      this.setState(state => ({
        ...state,
        loadedUserNames: true,
      }));
    }
    catch(e) {
      this.setState(state => ({
        ...state,
        error: 'Impossible de charger la liste des protopotes :-(',
      }));
    }
  }
  
  fetchUser() {
    const state = this.state;
  
    this.setState(state => ({
      ...state,
      userDescription: null,
    }), async () => {
      try {
        try {
          const userDescriptionRaw = await axios.get(`https://raid.protopotes.stream/api/description?login=${userNames[state.selectedUser]}`);
    
          this.setState(state => ({
            ...state,
            userDescription: userDescriptionRaw.data,
          }));
        }
        catch(e) {
          const userDescriptionRaw = await axios.get(`/api/description?u=${userNames[state.selectedUser]}`);
          console.log(userDescriptionRaw);
    
          this.setState(state => ({
            ...state,
            userDescription: userDescriptionRaw.data,
          }));
        }
      }
      catch(e) {
        this.setState(state => ({
          ...state,
          userDescription: '(Pas de description)',
        }));
      }
    });
  }
  
  render() {
    const props = this.props;
    const state = this.state;
    return (
      <div className={props.className}>
        <h2 className="text-center">Un Protopote au hasard</h2>
        
        {(state.loadedUserNames && state.userDescription !== null) ? this.renderProtopoteDetails() : <LoadingView/>}
  
        <br/>
        {state.loadedUserNames && (
          <div className="text-center">
            <button onClick={() => {
              this.setState(state => ({
                ...state,
                selectedUser: (state.selectedUser+1)%userNames.length,
              }), () => {
                this.fetchUser();
              });
            }}>Un autre !</button>
          </div>
        )}
      </div>
    );
  }
  
  renderProtopoteDetails() {
    const props = this.props;
    const state = this.state;
    if (state.error) {
      return (
        <div className="text-center">
          {state.error}
        </div>
      )
    }
    else {
      return (
        <div>
          <div className="text-center">
            <a href={`https://www.twitch.tv/${userNames[state.selectedUser].toLowerCase()}`} target="_blank" rel="noopener">
              <img className="image-round" width="76" height="76" src={`/api/pic?u=${userNames[state.selectedUser]}`} alt={userNames[state.selectedUser]} />
              <h3 className="nomargin" style={{color: 'white'}}>{userNames[state.selectedUser]}</h3>
              twitch.tv/{userNames[state.selectedUser].toLowerCase()}
            </a>
          </div>
          
          <br/>
    
          <p className="section-random-protopote__description">
            {state.userDescription}
          </p>
        </div>
      );
    }
  }
}

export { RandomProtopote };
