import React from 'react';
import Styled from 'styled-components';

let Section = (props) => {
  return (
    <section className={`${props.className || ''}`}>
      <div className="section__content">
        {props.children}
      </div>
    </section>
  );
};

//language=SCSS
Section = Styled(Section)`
& {
  padding: 60px;
  
  .section__content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
}
`;

export { Section };
