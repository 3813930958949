import React from 'react'; //eslint-disable-line no-unused-vars
import PropTypes from 'prop-types'; //eslint-disable-line no-unused-vars
import Styled from 'styled-components';
import { medias } from '../styles/medias';
import { GatsbyImage } from 'gatsby-plugin-image';

class AboveFold extends React.Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
  };
  
  state = {
    currentImage: 0,
  };
  
  loadedImages = {};
  
  constructor(props) {
    super(props);
    this.state.currentImage = Math.floor(Math.random()*props.images.length);
  }
  
  componentDidMount() {
    const props = this.props;
    setInterval(() => {
      this.setState(state => ({
        ...state,
        currentImage: (state.currentImage+1) % props.images.length,
      }))
    }, 4000);
  }
  
  render() {
    const props = this.props;
    const state = this.state;
    const currentImageIndex = state.currentImage;
    const nextImageIndex = (state.currentImage+1) % props.images.length;
    
    if (!this.loadedImages[currentImageIndex]) {
      this.loadedImages[currentImageIndex] = props.images[currentImageIndex];
    }
    if (!this.loadedImages[nextImageIndex]) {
      this.loadedImages[nextImageIndex] = props.images[nextImageIndex];
    }
    
    return (
      <section className={`${props.className} above-fold`}>
        {Object.values(this.loadedImages).map(image => {
          return (
            <div key={image} className={`bg ${props.images[currentImageIndex] === image ? 'bg__visible' : ''}`} style={{backgroundImage: `url(${image})`}} />
          );
        })}
        <div className="content">
          {props.children}
        </div>
      </section>
    );
  }
}

//language=SCSS
AboveFold = Styled(AboveFold)`
& {
  height: 85vh;
  overflow: hidden;
    @media screen and (${medias.mdMin}) {
      height: 70vh;
    }
    
  //background-image: url(/bg_bees_black_sm.jpg);
  //@media screen and (${medias.mdMin}) {
  //    background-image: url(/bg_bees_black.jpg);
  //}
  background-size: cover;
  background-position: center;
  
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.54) 35%, rgba(0, 0, 0, 0.62) 50%, rgba(0, 0, 0, 0.54) 65%, rgba(0, 0, 0, 0.2));
    top: 0;
    z-index: 10;
  }
  
  > * {
    position: relative;
    z-index: 3;
  }
  
  > .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-size: cover;
    background-position: center;
    opacity: 0.0;
    will-change: opacity;
    transition: opacity 1s ease-in-out 1s;
    z-index: 1;
    
    &.bg__visible {
      opacity: 1.0;
      transition: opacity 1s ease-in-out;
      z-index: 2;
    }
    
    .gatsby-image-wrapper {
      position: relative;
      min-height: 100%;
      transform: translate(0, -50%);
      top: 50%;
      overflow: hidden;
    }
  }
  
  > .content {
    width: 100%;
    z-index: 20;
    padding: 0 5px;
    text-align: center;
  }
  color: #fff;
  text-shadow: 0 0 3px #000, 0 0 6px #000;
  
  h1 {
    margin-bottom: 20px;
    color: #fff;
  }
  
  .main-description {
    text-shadow: 0 0 3px #000;
  }
}
`;

export { AboveFold };
