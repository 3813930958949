import React from 'react'; //eslint-disable-line no-unused-vars
import { css, keyframes } from 'styled-components';
import Styled from 'styled-components';
import loadingImage from '../images/loading.png';

const kf = keyframes`
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(${360 * 4}deg);
    }
`;
const animation = css`${kf} 2s infinite ease-in-out`;

const W = 80;
const containerStyle = {
    position: 'relative',
    display: 'inline-block',
    width: `${W}px`,
    height: `${W}px`,
    margin: 15,
    //background: 'url(/public/images/loading.png)',
    background: `url(${loadingImage})`,
    backgroundSize: 'contain',
    borderRadius: W,
    opacity: 0.6,
};

class LoadingView extends React.PureComponent {
    render() {
        return (
            <div className={`${this.props.className} loading-view`}>
                <div style={containerStyle}/>
            </div>
        );
    }
}
//language=SCSS
LoadingView = Styled(LoadingView)`
& {
    text-align: center;
    line-height: 0;
    
    > div {
        animation: ${animation};
    }
}
`;

export { LoadingView };
