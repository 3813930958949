import lab_neodyme from '../images/lab_neodyme.jpg';
import babartronic from '../images/babartronic.jpg';
import durss_artifact from '../images/durss_artifact.jpg';
import durss_box from '../images/durss_box.jpg';
import hippo_batteries from '../images/hippo_batteries.jpg';
import hippo_3dprint from '../images/hippo_3dprint.jpg';
import couture_chat3 from '../images/couture_chat3.jpg';

export const aboveFoldImages = [
  lab_neodyme,
  durss_artifact,
  hippo_batteries,
  couture_chat3,
  babartronic,
  durss_box,
  hippo_3dprint,
];
