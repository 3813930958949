import React from 'react';
import Styled from 'styled-components';
import { FlexLayout, FlexChild } from 'xureact/lib/module/components/layout/flex-layout';
import { StaticImage } from 'gatsby-plugin-image';

const headerHeight = 64;

let Header = (props) => (
	<div className={props.className}>
		<FlexLayout direction="row">
			<FlexChild width={headerHeight} grow={0} className="logo-wrapper">
				<a href="/">
					<StaticImage
						src="../images/logo.png"
						className="logo"
						alt="Les Protopotes"
					/>
				</a>
			</FlexChild>
			
			<FlexChild width={1} grow={1}>
			</FlexChild>
			
			<FlexChild width={300} grow={0} className="header__menu-wrapper">
				<a target="_blank" rel="noreferrer" href="https://raid.protopotes.stream">
					Qui est en live ?
				</a>
				<a href="/noel">
					Noël
				</a>
				{/*<a href="/noel">*/}
				{/*	News*/}
				{/*</a>*/}
			</FlexChild>
		</FlexLayout>
	</div>
);

//language=SCSS
Header = Styled(Header)`
& {
	position: sticky;
	top: 0;
	height: ${headerHeight}px;
	z-index: 100;
	background: #202020;
	padding: 5px;
	box-shadow: 0 0 5px rgba(0,0,0, 0.4);
	
	.logo-wrapper {
		height: 100%;
		color: #fff !important;
		
		img {
			height: 100%;
		}
	}
	
	.header__menu-wrapper {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		
		a {
			margin-right: 25px;
			text-decoration: none;
			color: #9e9eff;
			
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
`;

export { Header };
